import React from 'react';
import { graphql } from 'gatsby';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import InfoSection from 'components/molecules/InfoSection';
import Button from 'components/atoms/Button';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';

const Wspolpraca = ({
  data: {
    contentfulPartnership: {
      banner: { bannerSlide },
      introText,
      gymInfo: { heading: gymHeading, text: gymText, image: gymImage },
      personelInfo: { heading: personelHeading, text: personelText, image: personelImage },
      contactButton: { buttonLabel, reference },
    },
  },
}) => {
  const buttonVariants: Variants = {
    hidden: {
      opacity: 0,
      y: 80,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1,
        duration: 0.5,
      },
    },
  };

  return (
    <div className="partnership">
      <Seo title="Współpraca" />
      <Banner slides={bannerSlide} />
      <Container>
        <IntroSection text={introText} justify="center" />
        <div className="partnership__info-wrapper">
          <InfoSection heading={gymHeading} text={gymText} image={gymImage} />
          <InfoSection
            heading={personelHeading}
            text={personelText}
            image={personelImage}
            side="right"
          />
        </div>
        <motion.div variants={buttonVariants} initial="hidden" whileInView="visible">
          <Button
            buttonLabel={buttonLabel}
            variant="primary-solid"
            reference={reference}
            customClass="partnership__button"
          />
        </motion.div>
      </Container>
    </div>
  );
};

export const query = graphql`
  {
    contentfulPartnership {
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      contactButton {
        buttonLabel
        reference {
          ... on ContentfulContactUs {
            slug
          }
        }
      }
      introText
      gymInfo {
        heading
        text {
          text
        }
        image {
          image {
            gatsbyImageData(formats: WEBP)
          }
          altText
        }
      }
      personelInfo {
        heading
        text {
          text
        }
        image {
          image {
            gatsbyImageData(formats: WEBP)
          }
          altText
        }
      }
    }
  }
`;

export default Wspolpraca;
